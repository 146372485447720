  .shopping-page-redesign .category {
    position: relative;
  }
  .shopping-page-redesign .category .category-button {
    margin: 0 0 0 45px;
    padding: 10px;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
  }
  .shopping-page-redesign .category .settings-btn{
    display: none;
    position: relative;
    top: 7px;
  }
  .shopping-page-redesign .category .settings-btn button{
    padding: 0 4px !important;
  }
  .shopping-page-redesign .category button {
    text-align: center;
    font-weight: 400 !important;
    margin: 2px 4px;
    padding: 0px 18px;
    height: 33px;
    line-height: 17px;
    font-size: 14px;
    border-radius: 4px;
  }
  .shopping-page-redesign .category .partition {
    position: absolute;
    left: 40px;
    z-index: 999;
    top: 6px;
    width: 4px;
    height: 48px;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
  }
  .shopping-page-redesign .category .expand {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 2px;
    line-height: 45px;
    height: 45px;
    width: 45px;
    text-align: center;
    padding: 8px 0;
  }
  .shopping-page-redesign .category .expand button{
    padding: 0 4px;
  }
  .modal-done{
    position: absolute;
    right: 20px;
    top: 2px;
    line-height: 50px;
    text-decoration: underline;
    color: var(--theme-color-info);
  }
  .modal-category-select{
    margin-left: 20px;
  }

  @media (min-width: 640px){
    .modal-done{
      top: 10px;
    }
  }
  @media (min-width: 960px) {
    .shopping-page-redesign .category-button {
      margin: 0 10px !important;
    }
    .shopping-page-redesign .partition {
      display: none !important;
    }
    .shopping-page-redesign .expand {
      display: none !important;
    }
    .shopping-page-redesign .category .settings-btn{
      display: inline-block;
    }
    .modal-done {
      top: 14px;
    }
  }