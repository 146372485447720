.footer {
    max-width: 750px;
    width: 100%;
    margin: 24px auto;
    position: relative;
  }
  .footer .info-icon {
    position: absolute;
    left: 24px;
  }
.footer p {
    padding-left: 60px;
    padding-right: 24px;
  }

  .footer p a{
    display: inline-block;
    word-break: break-word;
    --c-link-text-color: var(--theme-color-interactive);
    --c-link-media-color: var(--theme-color-interactive);
    margin: var(--theme-link-margin);
    font-family: var(--theme-type-font-family);
    font-size: var(--theme-type-font-size-sm);
    font-weight: var(--theme-type-font-weight-medium);
    line-height: var(--theme-type-line-height);
    color: var(--c-link-text-color);
  }
  @media (min-width: 992px) and (max-width: 1254px) {
    .footer {
      max-width: 850px !important;
    }
    .footer p a{
      font-size: var(--theme-type-font-size);
    }
  }
  @media (min-width: 1255px) {
    .footer {
      max-width: 1100px !important;
    }
    .footer p a{
      font-size: var(--theme-type-font-size);
    }
  }