@import '~@barclays/blueprint-fonts-expert-sans';
@import '~@barclays/blueprint-fonts-effra';
@import '~@barclays/blueprint-fonts-barclaycard';
@import '~@barclays/blueprint-fonts-open-sans';

  body{
      background-color: #f7f7f7;
  }
  body.modal-open{
    pointer-events: none;
    overflow: hidden;
  }

  body:has(.loading-overlay){
    pointer-events: none;
    overflow: hidden;
  }

  .page-header{
    height: 50px !important;
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: #f7f7f7;
  }
  .page-header h1{
    height: 100%;
    width: 100%;
    line-height: 50px;
    text-align: center;
    background-color: white;
  }
  .page-header .back-btn{
    position: absolute;
    line-height: 50px;
    height: 45px;
    padding-left: 5px;
    padding-top: 5px;
    width: 50px;
    cursor: pointer;
}
.page-header .close-btn{
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.page-header .close-btn button{
  font-size: 14px;
  line-height: 24px;
}

.sp-container.padding-top{
  padding-top: 50px !important;
}
.error-page.padding-top{
  padding-top: 50px !important;
}
  .loading-overlay{
    position: fixed; 
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.85);
    z-index: var(--theme-modal-z-index);
    pointer-events: none;
    overflow-x: hidden;
  }
  .loading-overlay .loading{
    display: flex;
    justify-content: center;
    background: white;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 4px;
  }

  a{
    display: inline-block;
    word-break: break-word;
    --c-link-text-color: var(--theme-color-interactive);
    --c-link-media-color: var(--theme-color-interactive);
    color: var(--c-link-text-color);
  }
  .loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .align-center{
    text-align: center;
  }
  .shopping-page-redesign {
    height: 100%;
    font-family: var(--theme-type-font-family);
    background-color: var(--theme-color-30);
  }
  .shopping-page-redesign .content{
    line-height: 20px;
  }
  .shopping-page-redesign .footer-content{
    color: #666;
  }
  .shopping-page-redesign hr{
    margin-left: 6px;
    margin-right: 6px;
  }
  .shopping-page-redesign .category-header {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: var(--theme-color-0);
  }
  .shopping-page-redesign .offer-list-wrapper {
    padding: 70px 16px 5px 16px;
    max-width: 1100px;
    margin: 0 auto;
  }
  .category-details{
    padding: 15px 0px;
  }
  .category-details h1{
    font-size: 22px !important;
  }
  .shopping-page-redesign .offer-list-wrapper .category-info {
    padding: 16px;
    background-color: #E5F1F7;
    color: var(--theme-color-60);
    border-radius: 8px;
    margin: 8px 0px;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .empty-offer {
    height: 100vh;
    text-align: center;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item {
    padding: 16px 52px;
    background: var(--theme-color-0);
    border-radius: 8px;
    position: relative;
    margin-bottom: 16px;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item:focus{
    background-color: var(--theme-color-10) !important;
    outline: var(--theme-focus-width) solid transparent !important;
    outline-offset: var(--theme-focus-width) !important;
    box-shadow: var(--theme-focus-box-shadow) !important;
  }

  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .o-icon {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 16px;
    margin-top: 1px;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .card-icon {
    display: block;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .card-img {
    display: none;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .o-name {
    padding: 0px;
    margin: 0px;
    text-align: start;
    font-weight: var(--theme-type-font-weight);
    color: var(--theme-color-info) !important;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .arrow-right{
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
  }
  .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .o-badges img {
    height: 28px;
    display: block;
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    .shopping-page-redesign .offer-item {
      padding: 16px 64px 16px 150px !important;
      min-height: 185px;
    }
    .shopping-page-redesign .offer-item .o-icon {
      width: 118px !important;
      left: 16px !important;
      height: auto !important;
    }
    .shopping-page-redesign .offer-item .o-badges img {
      display: inline-block !important;
    }
    .shopping-page-redesign .card-icon {
      display: none !important;
    }
    .shopping-page-redesign .card-img {
      display: block !important;
    }
    .shopping-page-redesign .o-badges img {
      display: inline-block !important;
    }
    .shopping-page-redesign .o-badges img:nth-child(3) {
      margin-left: 8px;
    }
  }
  /****** Product Detail ******/
  body:has(#productDetailsPage) {
    background-color: #f5f5f5;
  }
  #productDetailsPage .header {
    position: relative;
    padding: 6px 16px;
    height: 24px;
    background-color: var(--theme-color-0);
  }
  #productDetailsPage .header .back-btn{
    position: absolute;
    line-height: 0px;
    height: 0px;
    width: 0px;
    cursor: pointer;
    padding-top: 0px;
  }
#productDetailsPage .header .back-btn button:focus {
    outline: none;
    outline-offset: none;
    box-shadow: none;
}

  #productDetailsPage .header h1 { 
    line-height: 24px;
    font-weight: var(--theme-type-font-weight-bold) !important;
  }
  #productDetailsPage .product-intro {
    align-items: center;
    position: relative;
    padding: 0px 0px 24px;
    gap: 38px;
    width: 100%;
    height: auto;
    background: var(--theme-color-0);
  }
  #productDetailsPage .product-intro .product-bg {
    height: 180px;
    width: 100%;
    background: url(../../../public/images/waves-bg.png);
    background-repeat: no-repeat;
    background-position: center center;
  }
  #productDetailsPage .product-intro .product-info {
    text-align: center;
  }
  #productDetailsPage .product-intro .product-info .card-img {
    position: absolute;
    width: auto;
    height: 160px;
    left: 50%;
    top: 130px;
    transform: translate(-50%,-58%);
  }
  #productDetailsPage .product-intro .product-info .p-name {
    padding: 26px 16px 0px 16px;
  }
  #productDetailsPage .product-intro .product-info .p-badges {
    margin-top: 16px;
  }
  #productDetailsPage .product-intro .product-info .p-badges img {
    height: 28px;
  }
  #productDetailsPage .pre-appr {
    padding: 16px 24px !important;
  }
  #productDetailsPage .pre-appr img {
    height: 24px;
    margin-right: 10px;
    position: relative;
    top: 5px;
  }
  #productDetailsPage .pre-appr p {
    margin:10px 0 10px 34px;
    color: #333333;
    line-height: 16px !important;
  }
  #productDetailsPage .section-wrapper {
    margin-top: 24px;
    padding: 24px;
    background-color: var(--theme-color-0);
  }
  #productDetailsPage .section-wrapper .section-content {
    max-width: 640px;
    width: 100%;
    margin: 0px auto;
  }
  #productDetailsPage .products-bullets .bullet-item{
    margin-bottom: 16px;
  }
  #productDetailsPage .products-bullets .bullet-item:last-child{
    margin-bottom: 1%;
  }
  #productDetailsPage .products-bullets h3 {
    font-family: var(--theme-type-font-family);
    font-weight: var(--theme-type-font-weight);
    font-size: var(--theme-type-font-size-md);
    margin: 0 0 8px 0;
    line-height: var(--theme-type-line-height-lg);
    color: var(--theme-color-70);
  }
  #productDetailsPage .products-bullets p {
    color: #333333;
    margin: 0px;
    font-family: var(--theme-type-font-family);
    font-size: var(--theme-type-font-size);
    line-height: 20px;
  }
  #productDetailsPage .summary-link {
    display: inline-block;
    color: var(--theme-color-interactive);
    text-decoration: underline;
    margin: 20px 0px 24px 0;
  }
  #productDetailsPage .eligibility-btn {
    margin: 16px auto 1% auto;
    width: 100%;
  }
  #productDetailsPage #foot1:focus{
    color: #0076b6;
    outline-offset: 1px;
    outline: 2px solid var(--theme-color-primary);
    box-shadow: none;
  }
  #productDetailsPage h2{
    font-size: 22px !important;
  }
  @media (min-width: 768px) {
    #productDetailsPage .rep-h3{
      text-align: center;
    }
  }
  @media (min-width: 960px){
    #productDetailsPage .product-intro .product-info .p-name {
      padding-top: 56px;
    }
    .shopping-page-redesign .category-header {
      padding-top: 0px !important;
    }
    #productDetailsPage h2{
      font-size: 32px !important;
    }
    .category-details h1{
      font-size: 32px !important;
    }
    #productDetailsPage .btn-container{
      width: 664px;
      margin: auto;
    }
    #productDetailsPage .btn-container .eligibility-btn:nth-child(1){
      float: right;
    }
    #productDetailsPage .btn-container .eligibility-btn{
      display: inline-block;
      margin: 16px;
      width: 100%;
      max-width: 300px;
    }
  } 
  @media (min-width: 960px) and (max-width: 1254px) {
    #productDetailsPage .section-wrapper .section-content {
      max-width: 850px !important;
    }
    .shopping-page-redesign .offer-item {
      padding-left: 160px !important;
    }
  }
  @media (min-width: 1255px) {
    #productDetailsPage .section-wrapper .section-content {
      max-width: 1100px !important;
    }
    .shopping-page-redesign .offer-item {
      padding-left: 160px !important;
    }
    #productDetailsPage .product-intro .product-bg {
      background-size: 100% 100%;
    }
    .sp-container h1,.sp-container h2{
      font-weight: var(--theme-type-font-weight-light);
    }
  }

  .txt-xs{
    font-size: var(--theme-type-font-size-xs) !important;
    line-height: var(--theme-type-line-height-xs) !important;
  }
  .txt-sm{
    font-size: var(--theme-type-font-size-sm) !important;
    line-height: 20px !important;
  }
  .txt{
    font-size: var(--theme-type-font-size) !important;
    line-height: var(--theme-type-line-height-lg) !important;
  }
  @media (min-width: 960px) {
    .txt-xs{
      font-size: var(--theme-type-font-size-sm) !important;
      line-height: var(--theme-type-line-height-sm) !important;
    }
    .txt-sm{
      font-size: var(--theme-type-font-size) !important;
      line-height: var(--theme-type-line-height) !important;
    }
    .txt{
      font-size: 18px !important;
      line-height: var(--theme-type-line-height) !important;
    }
    .shopping-page-redesign .offer-list-wrapper .offer-list .offer-item .o-badges img {
      margin-bottom: 4px;
    }
    #productDetailsPage .product-intro .product-info .card-img {
      height: auto;
      width: 120px;
      top: 140px;
    }
    .sp-container h1,.sp-container h2,.sp-container h3{
      font-weight: var(--theme-type-font-weight-light) !important;
    }
    #productDetailsPage .products-bullets .bullet-item{
      margin-bottom: 24px;
    }
    #productDetailsPage .products-bullets p {
      font-size: 18px;
      line-height: var(--theme-type-line-height);
    }
  }

  .branch-instructions .instructions-title{
    font-size: 16px;
  }
  .branch-instructions p{
    font-size: 14px;
  }
  .branch-instructions{
    margin-top: 16px;
  }
  .branch-instructions div:first-child{
    background-color: white;
    border-left-width: 6px;
  }

  .branch-instructions ol{
    padding-left: 20px;
  }
  .branch-instructions ul{
    padding-left: 20px;
    margin: 0px;
  }

  .branch-instructions.bottom li{
    list-style-type: none;
  }
.no-offer-found{
  height: 100vh;
  background: white;
}

.no-offer-found .hero-alert-wrapper{
  margin: 24px 0;
}
.error-page{
  height: 100vh;
}
@media (max-width: 768px) {
  .error-page button{
    padding: 8px 24px !important;
  }
}