.representative p span:first-child {
    width: 40%;
    display: inline-block;
    min-width: fit-content;
    max-width: 180px;
}
.representative strong{
  padding-left: 5px;
}
#productDetailsPage .representative p span:first-child {
  width: 65%;
  max-width: 150px;
}
#productDetailsPage .representative p {
  text-align: start;
}
#productDetailsPage .representative{
  max-width: 340px;
  text-align: center;
}

@media (min-width: 768px) {
  .shopping-page-redesign .representative p {
    display: inline-block;
    width: 50%;
  }
  #productDetailsPage .representative{
    margin: 0 auto;
  }
  .shopping-page-redesign .representative p:nth-child(3),p:nth-child(4) {
    margin-bottom: 4px;
  }
}
@media (min-width: 960px) {
  #productDetailsPage .representative{
    max-width: 380px;
  }
  #productDetailsPage .representative p span:first-child {
    width: 65%;
    max-width: 194px;
  }
  #productDetailsPage .representative p {
    margin-bottom: 16px;
  }
}