.pcci{
  color: var(--theme-type-heading-color) !important;
  font-weight: var(--theme-type-font-weight-bold) !important;
}
.pcci+button {
    font-size: 14px;
    display: block;
    margin-bottom: 24px;
  }

  @media (min-width: 960px) {
  .pcci+button {
    font-size: 16px;
  }
}