body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page-header{
  height: 50px !important;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #f7f7f7;
}
.error-page.padding-top{
  padding-top: 50px !important;
}
.loading-overlay{
  position: fixed; 
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.85);
  z-index: var(--theme-modal-z-index);
  pointer-events: none;
  overflow-x: hidden;
}
.loading-overlay .loading{
  display: flex;
  justify-content: center;
  background: white;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 4px;
}

a{
  display: inline-block;
  word-break: break-word;
  --c-link-text-color: var(--theme-color-interactive);
  --c-link-media-color: var(--theme-color-interactive);
  color: var(--c-link-text-color);
}
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
