#summaryBoxWrapper{
    background: white;
    height: 100vh;
}
#summaryBoxWrapper #summary-box{
    max-width: 1150px;
    margin: 0 auto;
    padding: 24px;
    background-color: white;
}

#summaryBoxWrapper .back-btn button {
    padding-top: 6px;
}
#summaryBoxWrapper .back-btn button:focus {
    outline: none;
    outline-offset: none;
    box-shadow: none;
}

#summaryBoxWrapper tr td,th{
    border-right: var(--theme-border-width) solid var(--theme-color-40);
}
#summaryBoxWrapper tr td:last-child,th:last-child{
    border-right: none;
}
#summaryBoxWrapper tr th{
    color: var(--theme-color-60);
    font-weight: bold;
    background-color: var(--theme-color-30);
}
#summaryBoxWrapper .secci-table.summarybox{
 width: 100%;
}
#summaryBoxWrapper .secci-table.summarybox th{
    width: 35%;
}
#summaryBoxWrapper .secci-table.summarybox th:first-child{
    border-top-left-radius: 0.25rem;
}
#summaryBoxWrapper .secci-table.summarybox th:last-child{
    border-top-right-radius: 0.25rem;
}
#summaryBoxWrapper .table-small-left td:first-child{
    color: var(--theme-color-60);
    font-weight: bold;
    width: 30%;
    background-color: var(--theme-color-30);
    border-top-left-radius: 0.25rem; 
    border-bottom-left-radius: 0.25rem;
    border-bottom: none;
}

#summaryBoxWrapper .table-two-col tr:first-child th{
    border-top-left-radius: 0.25rem; 
}
#summaryBoxWrapper .table-two-col tr:last-child th{
    border-bottom-left-radius: 0.25rem;
    border-bottom: none;
}
#summaryBoxWrapper .secci-table.summarybox[summary="Default fees"] th{
    border-top-left-radius: 0.25rem; 
    border-bottom-left-radius: 0.25rem;
    border-bottom: none;
}
#summaryBoxWrapper .summary-back-btn{
    padding-top: 24px;
    max-width: 1150px;
    margin: 0 auto;
}

#summaryBoxWrapper .header {
    position: relative;
    padding: 6px 16px;
    height: 24px;
    background-color: var(--theme-color-0);
}
#summaryBoxWrapper .header .back-btn{
    position: absolute;
    line-height: 0px;
    height: 0px;
    width: 0px;
    cursor: pointer;
}
#summaryBoxWrapper .header h1 { 
    line-height: 24px;
    font-weight: var(--theme-type-font-weight-bold) !important;
}
@media (min-width: 960px) {
    #summaryBoxWrapper .header h1 { 
        font-size: 24px;
    }
}